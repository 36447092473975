/* Footer CSS */

.footer{
    background-color:var(--dark-layer-color);
    padding:2rem;
    text-align: center;
    border-top:.0714rem solid var(--border-color-light);

    & p{
        color:var(--white-color);
        text-align:center;

        & a{
            color:var(--primary-color);
        }
    }
}

/* Scroll Top */
.scroll_top{
    position:fixed;
    right:30px;
    bottom:30px;
    z-index:99;
    cursor: pointer;
    width:2.8571rem;
    height:2.8571rem;
    line-height: 3.5rem;
    text-align:center;
    background-color:var(--primary-color);
    border-radius: 100%;

    & svg{
        text-align: center;
        width:1.5rem;
        height:1.5rem;
        border-radius: 50%;

        & path{
            fill:var(--white-color);
        }
    }
}
